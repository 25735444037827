import React, { useState } from "react";
import countriesData from "@assets/data/countries-ext.json";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { type SubmitHandler } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modal from "@components/common/Modal";
import { submitContactForm } from "@api";
import { getMessage as t } from "@messages";

interface Country {
    nameES: string;
    nameEN: string;
    iso2: string;
    iso3: string;
    phoneCode: string;
    flag: string;
}


const getSchema = (lang: string|undefined) => yup
    .object({
        name: yup.string()
            .required(t("home.contactus.form.name.error_empty", lang)),
        company: yup.string(),
        email: yup.string()
            .email(t("home.contactus.form.email.error_invalid", lang))
            .required(t("home.contactus.form.email.error_empty", lang)),
        country_code: yup.string(),
        phone: yup.string(),
        message: yup.string(),
    })
    .required();

const options = countriesData
    .sort((countryA: Country, countryB: Country) => {
        return countryA.iso3 > countryB.iso3 ? 1 : -1;
    }).map((country: Country) => {
        const value = `+${country.phoneCode}`;
        const label = `${country.flag} ${country.iso2} +${country.phoneCode}`;

        return { label, value };
    });

interface ContactFormProps {
    lang: string|undefined;
}

const ContactForm: React.FC<ContactFormProps> = ({lang}) => {
    const [formState, setFormState] = useState({ 
        isSubmitting: false,
        isShowingConfirmation: false,
        confirmationTitle: "",
        confirmationMessage: <></>,
    });
    const {
        control,
        register,
        handleSubmit,
        trigger,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(getSchema(lang)),
    });

    const onSubmit: SubmitHandler<any> = (data: any) => {
        setFormState({ ...formState, isSubmitting: true });

        submitContactForm(data).then(_ => {
            setFormState({ ...formState, 
                isSubmitting: false,
                isShowingConfirmation: true,
                confirmationTitle: t("home.contactus.popup.success.title", lang),
                confirmationMessage: <>
                    {t("home.contactus.popup.success.message", lang, {name: data.name})}
                </>,
            });
            reset();
        }).catch(_ => {
            setFormState({ ...formState, 
                isSubmitting: false,
                isShowingConfirmation: true,
                confirmationTitle: t("home.contactus.popup.error.title", lang),
                confirmationMessage: <>
                    {t("home.contactus.popup.error.message", lang)} <a className="styled-link" href="mailto:info@luxware.co">info@luxware.co</a>
                </>,
            });
        });
    };

    const onCloseConfirmation = () => {
        setFormState({...formState, isShowingConfirmation: false})
    }

    return (
        <>
            <form className="grid grid-cols-1 gap-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-span-full">
                    <label htmlFor="name" className="block text-sm font-medium leading-6">
                        {t("home.contactus.form.name", lang)}*
                    </label>
                    <div className="input-text-container">
                        <input
                            type="text"
                            id="name"
                            autoComplete="name"
                            className="input-text"
                            {...register("name")}
                            onBlur={() => trigger("name")}
                        />
                        {errors.name && (
                            <p className="text-red-500 text-xs m-1">{errors.name.message}</p>
                        )}
                    </div>
                </div>

                <div className="col-span-full">
                    <label htmlFor="company" className="block text-sm font-medium leading-6">
                        {t("home.contactus.form.company", lang)}
                    </label>
                    <div className="input-text-container">
                        <input
                            type="text"
                            id="company"
                            autoComplete="organization"
                            className="input-text"
                            {...register("company")}
                        />
                    </div>
                </div>

                <div className="col-span-full">
                    <label htmlFor="email" className="block text-sm font-medium leading-6">
                        {t("home.contactus.form.email", lang)}*
                    </label>
                    <div className="input-text-container">
                        <input
                            type="email"
                            id="email"
                            autoComplete="email"
                            className="input-text"
                            {...register("email")}
                            onBlur={() => trigger("email")}
                        />
                        {errors.email && (
                            <p className="text-red-500 text-xs m-1">{errors.email.message}</p>
                        )}
                    </div>
                </div>

                <div className="col-span-full">
                    <label htmlFor="phone" className="block text-sm font-medium leading-6">
                        {t("home.contactus.form.phone", lang)}
                    </label>
                    <div className="flex flex-row gap-2">
                        <Controller
                            name="country_code"
                            control={control}
                            render={({ field: { onChange, value, ...field } }) =>
                                <Select
                                    className="text-xs w-36 flag-emoji text-[--color-text-input]"
                                    options={options}
                                    placeholder=""
                                    aria-label="Phone Code"
                                    noOptionsMessage={(evt) => <span>{t("home.contactus.form.code.not_found", lang)} {evt.inputValue}</span>}
                                    value={options.find(option => option.value === value)}
                                    onChange={(selectedOption) => onChange(selectedOption?.value)}
                                    {...field}
                                />
                            }
                        />
                        <div className="input-text-container">
                            <input
                                type="tel"
                                id="phone"
                                autoComplete="tel-national"
                                className="input-text"
                                {...register("phone")}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-span-full">
                    <label htmlFor="message" className="block text-sm font-medium leading-6">
                        {t("home.contactus.form.message", lang)}
                    </label>
                    <textarea
                        id="message"
                        rows={3}
                        className="input-textarea"
                        {...register("message")}
                    ></textarea>
                </div>

                <div className="col-span-full">
                    <button className={`
                            form-button w-full h-10
                            ${formState.isSubmitting ? 'cursor-wait': ''}
                            ${!isValid ? 'disabled': ''}
                        `}
                        disabled={formState.isSubmitting}
                    >
                        {t("home.contactus.form.submit", lang)}
                    </button>
                </div>
            </form>
            <Modal 
                title={formState.confirmationTitle}
                show={formState.isShowingConfirmation}
                onClose={onCloseConfirmation}
            >
                <p className="max-w-xl">
                    {formState.confirmationMessage}
                </p>
                <footer className="flex flex-row justify-end mt-4 w-full">
                    <button className="form-button w-20 py-1" onClick={onCloseConfirmation}>{t("home.contactus.popup.confirm", lang)}</button>
                </footer>
            </Modal>
        </>

    );
};

export default ContactForm;
