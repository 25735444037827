// Modal.tsx
import React, { type ReactNode } from 'react';

interface ModalProps {
    show: boolean;
    onClose?: () => void;
    title?: string;
    children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, title, children }) => {
    return (
        <div className={`fixed inset-0 bg-gray-800 bg-opacity-65 flex justify-center items-center transition-opacity duration-300 ${show ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={onClose}>
            <div className={`bg-[--color-background-offset] rounded-lg p-8 relative transition-transform duration-300 ${show ? 'modal-enter' : 'modal-exit'}`} onClick={(e) => e.stopPropagation()}>
                <button className="absolute top-2 right-2 text-[--color-text] hover:scale-125" onClick={onClose}>
                    &times;
                </button>
                {title && <h2 className="text-2xl font-semibold mb-4 text-center">{title}</h2>}
                <div className="mb-4">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
