import enMessages from './en.json';
import esMessages from './es.json';

const messages: { [key: string]: { [key: string]: string } } = {
    en: enMessages,
    es: esMessages,
};

interface InterpolationValues {
    [key: string]: string;
}

export function getMessage(key: string, lang: string = 'en', values?: InterpolationValues): string {
    let message = messages[lang][key] || key;

    if (values) {
        message = message.replace(/\${(.*?)}/g, (_, variable) => {
            return values[variable] || `$\{${variable}}`;
        });
    }

    return message;
}
