import { env } from "@utils";

const API_BASE_URL = env('API_URL');

type ApiMethod = "GET"
    | "POST"
    | "PUT"
    | "DELETE";

const request = async (url: string, method: ApiMethod, data: any) => {
    try {
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('There was a problem with your fetch operation:', error);
        throw error;
    }
};

export const submitContactForm = async (contactData:any) => {
    const url = `${API_BASE_URL}/contacts`;
    return request(url, 'POST', {data: contactData});
};
